<template>
  <div class="w-full px-8">
    <div class="px-8 py-3 my-12 bg-white rounded container">
      <h4 class="font-subtitle-2 text-subtitle-1 my-6">
        {{ getModeTitleLabel() }}
      </h4>
      <FormSubscription
        :title="title"
        :detail="detail"
        :date="date"
        :price="price"
        :read-only="isReadOnly()"
      />
      <div class="my-12 w-full">
        <ButtonSolidBox class="px-8" @click="commit()">
          {{ getSubmitButtonLabel() }}
        </ButtonSolidBox>
      </div>
    </div>
    <Modal name="subscription" :show.sync="showModal">
      <div class="flex flex-col align-items-center w-full p-12">
        <div class="mx-auto my-8 text-center">
          <svg
            class="block mx-auto"
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M63.25 32C63.25 49.2589 49.2589 63.25 32 63.25C14.7411 63.25 0.75 49.2589 0.75 32C0.75 14.7411 14.7411 0.75 32 0.75C49.2589 0.75 63.25 14.7411 63.25 32Z"
              fill="white"
              fill-opacity="0.12"
            />
            <path
              d="M63.25 32C63.25 49.2589 49.2589 63.25 32 63.25C14.7411 63.25 0.75 49.2589 0.75 32C0.75 14.7411 14.7411 0.75 32 0.75C49.2589 0.75 63.25 14.7411 63.25 32Z"
              fill="#48D5D1"
              fill-opacity="0.12"
            />
            <path
              d="M63.25 32C63.25 49.2589 49.2589 63.25 32 63.25C14.7411 63.25 0.75 49.2589 0.75 32C0.75 14.7411 14.7411 0.75 32 0.75C49.2589 0.75 63.25 14.7411 63.25 32Z"
              stroke="#48D5D1"
              stroke-width="1.5"
            />
            <path
              d="M46.334 26.0133L28.706 43.6414L17.666 32.6014L23.028 27.2395L28.706 32.9174L32 29.6234L40.972 20.6514L46.334 26.0133Z"
              fill="#48D5D1"
            />
          </svg>
          <div class="block mt-12 font-headline-6 text-headline-6 text-gray">
            Subcription has been Created!
          </div>
          <div
            class="block font-body-1 text-body-1 text-gray-500 max-w-sm mb-5"
          >
            Subscription details have been successfully saved on the system.
          </div>
          <ButtonSolidBox class="px-8" @click="closeModal()">
            Ok
          </ButtonSolidBox>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import FormSubscription from '@/components/Forms/FormSubscription.vue'
import ButtonSolidBox from '@/components/Buttons/ButtonSolidBox.vue'
import Modal from '@/components/Modal.vue'

export default {
  components: {
    FormSubscription,
    ButtonSolidBox,
    Modal
  },
  data () {
    return {
      showModal: false,
      title: '',
      detail: '',
      date: null,
      price: ''
    }
  },
  computed: {
    currentMode () {
      if (
        Object.prototype.hasOwnProperty.call(this.$route.params, 'id') &&
        `${this.$route.params.id}`.length > 0 &&
        this.$route.name === 'route.dashboard.subscription.editor.edit'
      ) {
        return 'update'
      }
      if (
        Object.prototype.hasOwnProperty.call(this.$route.params, 'id') &&
        `${this.$route.params.id}`.length > 0 &&
        this.$route.name === 'route.dashboard.subscription.overview'
      ) {
        return 'overview'
      }
      return 'creation'
    }
  },
  mounted () {
    if (this.currentMode === 'overview' || this.currentMode === 'update') {
      this.loadData()
    }
  },
  methods: {
    loadData () {
      this.title = 'Gold'
      this.detail =
        'Our lives are full of waste. I’ve lost entire Saturdays going down stupid wormholes. But only a day was lost. The price can be so much higher if we don’t adequately police our behavior.'
      this.date = new Date(1637769600000)
      this.price = '50.000 IDR'
    },
    isReadOnly () {
      return this.currentMode === 'overview'
    },
    getSubmitButtonLabel () {
      if (this.currentMode === 'update') {
        return 'Update'
      }
      if (this.currentMode === 'overview') {
        return 'Edit'
      }
      return 'Create'
    },
    getModeTitleLabel () {
      if (this.currentMode === 'update') {
        return 'Edit Subscribtion'
      }
      if (this.currentMode === 'overview') {
        return 'Subscription Detail'
      }
      return 'Create New Subscription'
    },
    commit () {
      if (this.currentMode === 'update') {
        this.showModal = true
      }
      if (this.currentMode === 'overview') {
        return this.$router.push({
          name: 'route.dashboard.subscription.editor.edit',
          params: {
            id: 0
          }
        })
      }
      this.showModal = true
    },
    closeModal () {
      this.showModal = false
    }
  }
}
</script>
