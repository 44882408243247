<template>
  <div>
    <InputTextBox
      placeholder="Subcription title"
      :value="title"
      :disabled="isReadOnly()"
    ></InputTextBox>
    <InputTextArea
      class="w-full"
      :value="detail"
      placeholder="Detail"
      :disabled="isReadOnly()"
    ></InputTextArea>
    <div class="flex flex-row gap-x-8 py-3">
      <InputTextBox
        type="date"
        class="inline-block w-1/2 h-12"
        :value="date"
        placeholder="Publish Date"
        :disabled="isReadOnly()"
      ></InputTextBox
      ><InputTextBox
        class="inline-block w-1/2 h-12"
        :value="price"
        placeholder="Price"
        :disabled="isReadOnly()"
      ></InputTextBox>
    </div>
  </div>
</template>

<script>
import InputTextBox from '@/components/Inputs/InputTextBox.vue'
import InputTextArea from '@/components/Inputs/InputTextArea.vue'

export default {
  components: {
    InputTextBox,
    InputTextArea
  },
  props: {
    mode: {
      type: String,
      validator (value) {
        return ['read-only', 'disabled', 'edit'].indexOf(value) !== -1
      },
      default () {
        return 'edit'
      }
    },
    readOnly: {
      type: Boolean,
      default () {
        return false
      }
    },
    title: {
      type: String,
      default () {
        return ''
      }
    },
    detail: {
      type: String,
      default () {
        return ''
      }
    },
    date: {},
    price: {
      type: String,
      default () {
        return ''
      }
    }
  },
  methods: {
    isReadOnly () {
      return this.mode === 'read-only' || this.readOnly
    }
  }
}
</script>
